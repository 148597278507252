<template>
    <v-form v-if="passportViewModel" ref="form" v-model="isFormValid" lazy-validation @submit="submit">
        <v-container class="pa-0">
            <v-row  no-gutters>
                <v-col cols="6">
                    <v-select
                        slot="prepend"
                        v-model="model.country"
                        :items="countries"
                        item-text="text"
                        item-value="value"
                        :label="$t('views.passport.loginWithMobile.country')"
                    />
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        v-model="model.mobilePhone"
                        :rules="[rules.required]"
                        :validate-on-blur="false"
                        :error="hasError('mobilePhone')"
                        :error-messages="getError('mobilePhone')"
                        :label="$t('views.passport.loginWithMobile.mobilePhone')"
                        :counter="phoneNumberLength"
                        name="mobilePhone"
                        @change="resetError('mobilePhone')"
                    />
                </v-col>

                <v-col cols="12">
                    <v-text-field
                        v-model="model.captcha"
                        :rules="[rules.required]"
                        :validate-on-blur="false"
                        :error="hasError('captcha')"
                        :error-messages="getError('captcha')"
                        :label="$t('views.passport.loginWithMobile.captcha')"
                        :counter="passportViewModel.captchaOptions.length"
                        :disabled="captchaInputDisabled"
                        name="captcha"
                        autocomplete="off"
                        @change="resetError('captcha')"
                    >
                        <v-btn slot="append" color="primary" text :disabled="sendCaptchaButtonDisabled" @click="sendLoginSmsCaptcha">
                            {{ countDownSeconds > 0 ? `(${countDownSeconds}s)` : $t('views.passport.loginWithMobile.sendCaptcha') }}
                        </v-btn>
                    </v-text-field>
                </v-col>
            </v-row>
        </v-container>

        <v-btn class="mt-2" type="submit" color="primary" block x-large :loading="isSubmitting" :disabled="submitButtonDisabled">
            {{ $t('views.passport.login.button') }}
        </v-btn>

        <v-container class="mt-2">
            <v-row align="center" justify="center">
                <router-link class="ma-1" :to="`${generateUrl('/passport/login', { returnUrl })}#password`" @click.native="changeLoginType($event, 'password')">
                    {{ $t('views.passport.login.loginWithPassword') }}
                </router-link>
                <router-link class="ma-1" :to="`${generateUrl('/passport/login', { returnUrl })}#email`" @click.native="changeLoginType($event, 'email')">
                    {{ $t('views.passport.login.loginWithEmail') }}
                </router-link>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import _ from 'lodash';
import FormMixins from '@/mixins/FormMixins';
import countries from '@/models/countries';
import { sendLoginSmsCaptcha, loginWithMobile } from '@/api/passport';

export default {
    mixins: [FormMixins],
    props: {
        /**
         * 通行证的视图模型(包括配置)
         */
        passportViewModel: Object,

        /**
         * 登录成功后回跳的地址
         */
        returnUrl: String
    },
    data () {
        return {
            isSubmitting: false,

            // 表单
            isFormValid: false,
            model: {
                country: null,
                mobilePhone: '',
                captcha: '',
                captchaId: null,
                rememberLogin: true,
                returnUrl: null
            },
            // 验证规则
            rules: {
                required: (value) => (value && Boolean(value)) || this.$t('validation.required')
            },

            // 验证码倒计时
            countDownSeconds: 0
        };
    },
    computed: {
        countries () {
            return _(countries)
                .filter((c) => {
                    return this.passportViewModel.smsOptions.supportCountries.indexOf(c.value) >= 0;
                })
                .map((c) => {
                    return {
                        value: c.value,
                        text: this.$t(`models.countries.${c.value}`) + ` (+${c.callingCode})`,
                        phoneNumberLength: c.phoneNumberLength
                    };
                })
                .value();
        },
        phoneNumberLength () {
            return _.find(countries, (c) => c.value === this.model.country).phoneNumberLength;
        },
        captchaInputDisabled () {
            var disabled = this.model.mobilePhone.length === 0 ||
                this.model.mobilePhone.length !== this.phoneNumberLength;
            return disabled;
        },
        sendCaptchaButtonDisabled () {
            return this.isSubmitting || this.captchaInputDisabled || this.countDownSeconds > 0;
        },
        submitButtonDisabled () {
            return this.isSubmitting ||
                !(
                    (this.model.mobilePhone && this.model.mobilePhone.length === this.phoneNumberLength) &&
                    (this.model.captcha && this.model.captcha.length === this.passportViewModel.captchaOptions.length) &&
                    this.model.captchaId
                );
        }
    },
    watch: {
        currentCulture (val) {
            this.$refs.form.resetValidation();
        }
    },
    methods: {
        async sendLoginSmsCaptcha () {
            try {
                this.model.captchaId = await sendLoginSmsCaptcha({
                    country: this.model.country,
                    mobilePhone: this.model.mobilePhone
                });

                this.countDownSeconds = 60;
                var countDown = () => {
                    if (--this.countDownSeconds <= 0) {
                        clearInterval(countDownId);
                    }
                };
                var countDownId = setInterval(countDown, 1000);
            } catch (ex) {
                this.$toast.error(ex.message);
            }
        },
        async submit (e) {
            e.preventDefault();
            if (!this.$refs.form.validate()) return;

            this.isSubmitting = true;
            this.resetFormErrors();

            var postData = _.defaults(
                {
                    rememberLogin: this.model.rememberLogin && this.passportViewModel.allowRememberLogin
                },
                this.model);

            try {
                var { redirect } = await loginWithMobile(postData);
                // 跳转到指定地址, 因为redirect地址不一定是本站，所以需要用window.location.href
                // 而不能使用 this.$router.push(redirect); 因为$router.push不会访问到服务器
                window.location.href = redirect;
            } catch (ex) {
                this.setFormErrors(ex);
                this.$toast.error(ex.message);
            } finally {
                this.isSubmitting = false;
            }
        },
        changeLoginType (e, loginType) {
            this.$emit('loginTypeChanged', { loginType });
        }
    },
    created () {
        this.model.country = this.passportViewModel.smsOptions.defaultCountry;
        this.model.returnUrl = this.passportViewModel.returnUrl;
    }
};
</script>
