<template>
    <div>
        <v-card class="text-center pa-1" :loading="isLoading">
            <v-card-title class="justify-center display-1 mb-2">{{ title }}</v-card-title>
            <v-card-subtitle>{{ $t('views.passport.login.subtitle') }}</v-card-subtitle>

            <!-- 登录 -->
            <v-card-text v-if="passportViewModel">
                <LoginWithPassword
                    v-if="passportViewModel.isExternalLoginOnly || loginType === 'password'"
                    :passportViewModel="passportViewModel"
                    :returnUrl="returnUrl"
                    @loginTypeChanged="onLoginTypeChanged"
                />
                <LoginWithMobile
                    v-else-if="loginType === 'mobile'"
                    :passportViewModel="passportViewModel"
                    :returnUrl="returnUrl"
                    @loginTypeChanged="onLoginTypeChanged"
                />
                <LoginWithEmail
                    v-else-if="loginType === 'email'"
                    :passportViewModel="passportViewModel"
                    :returnUrl="returnUrl"
                    @loginTypeChanged="onLoginTypeChanged"
                />
            </v-card-text>
        </v-card>

        <div v-if="passportViewModel" class="text-center mt-6">
            {{ $t('views.passport.login.noaccount') }}
            <router-link :to="generateUrl('/passport/register', { returnUrl })" class="font-weight-bold">
                {{ $t('views.passport.login.create') }}
            </router-link>
        </div>

        <div class="text-center mt-2">
            <a href="https://beian.miit.gov.cn/">粤ICP备19050057号</a>
        </div>
    </div>
</template>

<script>
import { getPassportViewModel } from '@/api/passport';
import utils from '@/utils';
import LoginWithPassword from './LoginWithPassword';
import LoginWithMobile from './LoginWithMobile';
import LoginWithEmail from './LoginWithEmail';

export default {
    components: {
        LoginWithPassword,
        LoginWithMobile,
        LoginWithEmail
    },
    data () {
        return {
            isLoading: false,
            /**
             * 登录类型,可选值 password / mobile / email
             */
            loginType: 'password',

            /**
             * 返回地址
             */
            returnUrl: null,

            /**
             * 解析的视图模型
             */
            passportViewModel: null
        };
    },
    computed: {
        title: {
            get () {
                switch (this.loginType) {
                case 'mobile': return this.$t('views.passport.login.loginWithMobile');
                case 'email': return this.$t('views.passport.login.loginWithEmail');
                default: return this.$t('views.passport.login.loginWithPassword');
                }
            },
            set () {}
        }
    },
    methods: {
        onLoginTypeChanged ({ loginType }) {
            this.loginType = loginType;
        }
    },
    async created () {
        this.isLoading = true;

        // 获取配置
        var returnUrl = this.returnUrl = utils.route.getQueryString(this.$route, 'returnUrl');
        this.passportViewModel = await getPassportViewModel({ returnUrl });

        // 根据hash参数切换到对应的界面
        if (this.$route.hash === '#mobile') {
            this.loginType = 'mobile';
        } else if (this.$route.hash === 'email') {
            this.loginType = 'email';
        } else {
            this.loginType = 'password';
        }

        if (this.passportViewModel.isExternalLoginOnly) {
            alert('未实现该功能');
        }

        this.isLoading = false;
    }
};
</script>
