<template>
    <v-form ref="form" v-model="isFormValid" lazy-validation @submit="submit">
        <div v-if="passportViewModel.enableLocalLogin" class="my-3">
            <v-text-field
                v-model="model.username"
                :rules="[rules.required]"
                :validate-on-blur="false"
                :error="hasError('username')"
                :error-messages="getError('username')"
                :label="userNameLabel"
                name="username"
                outlined
                @change="resetError('username')"
            />

            <v-text-field
                v-model="model.password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="showPassword ? 'text' : 'password'"
                :error="hasError('password')"
                :error-messages="getError('password')"
                :label="$t('views.passport.loginWithPassword.password')"
                name="password"
                outlined
                @change="resetError('password')"
                @click:append="showPassword = !showPassword"
            />
            <v-btn type="submit" color="primary" block x-large :loading="isSubmitting" :disabled="submitButtonDisabled" >
                {{ $t('views.passport.login.button') }}
            </v-btn>
        </div>

        <div v-if="passportViewModel.enableLocalLogin && (passportViewModel.externalProviders.length > 0 && !passportViewModel.provision)"
            class="caption font-weight-bold text-uppercase my-3"
        >
            {{ $t('views.passport.login.orsign') }}
        </div>

        <!-- 第三方登录 -->
        <div v-if="(passportViewModel.externalProviders.length > 0 && !passportViewModel.provision)" class="my-3">
            <v-btn
                v-for="provider in providers"
                :key="provider.authenticationScheme"
                :loading="provider.isLoading"
                :disabled="isSubmitting"
                @click="signInExternalProviders(provider)"
                fab dark small class="primary lighten-2 ma-1"
            >
                <v-icon>mdi-{{ provider.authenticationScheme.toLowerCase() }}</v-icon>
            </v-btn>
        </div>

        <v-container>
            <v-row align="center" justify="center">
                <router-link v-if="passportViewModel.passportOptions.enableMobileLogin" class="ma-1" :to="`${generateUrl('/passport/login', { returnUrl })}#mobile`" @click.native="changeLoginType($event, 'mobile')">
                    {{ $t('views.passport.login.loginWithMobile') }}
                </router-link>
                <router-link v-if="passportViewModel.passportOptions.enableEmailLogin" class="ma-1" :to="`${generateUrl('/passport/login', { returnUrl })}#email`" @click.native="changeLoginType($event, 'email')">
                    {{ $t('views.passport.login.loginWithEmail') }}
                </router-link>
                <router-link class="ma-1" to="/passport/forgot-password">
                    {{ $t('views.passport.login.forgot') }}
                </router-link>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import _ from 'lodash';
import utils from '@/utils';
import { mapGetters } from 'vuex';
import FormMixins from '@/mixins/FormMixins';
import { loginWithPassword } from '@/api/passport';

export default {
    mixins: [FormMixins],
    props: {
        /**
         * 是否启用第三方账号登录。当在做账号关联(provision)的时候,就需要禁用掉
         */
        externalDisabled: Boolean,

        /**
         * 通行证的视图模型(包括配置)
         */
        passportViewModel: Object,

        /**
         * 登录成功后回跳的地址
         */
        returnUrl: String
    },
    data () {
        return {
            // sign in buttons
            isSubmitting: false,

            // form
            isFormValid: false,
            model: {
                username: '',
                password: '',
                rememberLogin: true,
                returnUrl: null
            },
            rules: {
                required: (value) => (value && Boolean(value)) || this.$t('validation.required')
            },

            // show password field
            showPassword: false,

            // 第三方登录
            providers: []
        };
    },
    computed: {
        ...mapGetters('session', [
            'currentCulture'
        ]),
        submitButtonDisabled () {
            return this.isSubmitting || !(this.model.username && this.model.password);
        },
        userNameLabel () {
            var ways = ['username'];
            if (this.passportViewModel.passportOptions.enableMobileLogin) {
                ways.push('mobile');
            }
            if (this.passportViewModel.passportOptions.enableEmailLogin) {
                ways.push('email');
            }
            var i18nKey = `views.passport.loginWithPassword.${ways.join('_')}`;
            return this.$t(i18nKey);
        }
    },
    watch: {
        currentCulture (val) {
            this.$refs.form.resetValidation();
        }
    },
    methods: {
        async submit (e) {
            e.preventDefault();
            if (!this.$refs.form.validate()) return;

            this.isSubmitting = true;
            this.resetFormErrors();

            var postData = _.defaults(
                {
                    rememberLogin: this.model.rememberLogin && this.passportViewModel.allowRememberLogin
                },
                this.model);

            try {
                var { redirect } = await loginWithPassword(postData);
                // 跳转到指定地址, 因为redirect地址不一定是本站，所以需要用window.location.href
                // 而不能使用 this.$router.push(redirect); 因为$router.push不会访问到服务器
                window.location.href = redirect;
            } catch (ex) {
                this.setFormErrors(ex);
                this.$toast.error(ex.message);
            } finally {
                this.isSubmitting = false;
            }
        },
        // 第三方登录
        signInExternalProviders (provider) {
            document.location = utils.route.generateUrl('/external/challenge', { scheme: provider.authenticationScheme, returnUrl: this.returnUrl });
        },
        // 改变登录方式
        changeLoginType (e, loginType) {
            this.$emit('loginTypeChanged', { loginType });
        }
    },
    created () {
        if (this.passportViewModel.username) {
            this.model.username = this.passportViewModel.username;
        }
        this.model.returnUrl = this.returnUrl;

        this.providers = _.map(this.passportViewModel.externalProviders, (p) => {
            return _.merge({}, p, { isLoading: false });
        });
    }
};
</script>
