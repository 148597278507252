<template>
    <v-form ref="form" v-model="isFormValid" lazy-validation @submit="submit">
        <v-text-field
            v-model="model.email"
            :rules="[rules.required, rules.email]"
            :validate-on-blur="false"
            :error="hasError('email')"
            :error-messages="getError('email')"
            :label="$t('views.passport.loginWithEmail.email')"
            name="email"
            @change="resetError('email')"
        />

        <v-text-field
            v-model="model.captcha"
            :rules="[rules.required]"
            :validate-on-blur="false"
            :error="hasError('captcha')"
            :error-messages="getError('captcha')"
            :label="$t('views.passport.loginWithEmail.captcha')"
            name="captcha"
            autocomplete="off"
            @change="resetError('captcha')"
        >
            <v-btn slot="append" color="primary" text :disabled="sendCaptchaButtonDisabled" @click="sendLoginEmailCaptcha">
                {{ countDownSeconds > 0 ? `(${countDownSeconds}s)` : $t('views.passport.loginWithEmail.sendCaptcha') }}
            </v-btn>
        </v-text-field>

        <v-btn class="mt-2" type="submit" color="primary" block x-large :loading="isSubmitting" :disabled="submitButtonDisabled">
            {{ $t('views.passport.loginWithEmail.button') }}
        </v-btn>

        <v-container class="mt-2">
            <v-row align="center" justify="center">
                <router-link class="ma-1" :to="`${generateUrl('/passport/login', { returnUrl })}#password`" @click.native="changeLoginType($event, 'password')">
                    {{ $t('views.passport.login.loginWithPassword') }}
                </router-link>
                <router-link class="ma-1" :to="`${generateUrl('/passport/login', { returnUrl })}#mobile`" @click.native="changeLoginType($event, 'mobile')">
                    {{ $t('views.passport.login.loginWithMobile') }}
                </router-link>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import _ from 'lodash';
import utils from '@/utils';
import FormMixins from '@/mixins/FormMixins';
import { sendLoginEmailCaptcha, loginWithEmail } from '@/api/passport';

export default {
    mixins: [FormMixins],
    props: {
        /**
         * 通行证的视图模型(包括配置)
         */
        passportViewModel: Object,

        /**
         * 登录成功后回跳的地址
         */
        returnUrl: String
    },
    data () {
        return {
            isSubmitting: false,

            // 表单
            isFormValid: true,
            model: {
                email: '',
                captcha: '',
                captchaId: null,
                rememberLogin: true,
                returnUrl: null
            },
            // 验证规则
            rules: {
                required: (value) => (value && Boolean(value)) || this.$t('validation.required'),
                email: (value) => (value && utils.validate.isEmail(value)) || this.$t('validation.invalidEmailFormat')
            },
            // 验证码倒计时
            countDownSeconds: 0
        };
    },
    computed: {
        captchaInputDisabled () {
            return !(this.model.email && utils.validate.isEmail(this.model.email));
        },
        sendCaptchaButtonDisabled () {
            return this.isSubmitting || this.captchaInputDisabled || this.countDownSeconds > 0;
        },
        submitButtonDisabled () {
            return this.isSubmitting ||
                !(
                    (this.model.email && utils.validate.isEmail(this.model.email)) &&
                    (this.model.captcha && this.model.captcha.length === this.passportViewModel.captchaOptions.length) &&
                    (this.model.captchaId)
                );
        }
    },
    watch: {
        currentCulture (val) {
            this.$refs.form.resetValidation();
        }
    },
    methods: {
        async sendLoginEmailCaptcha () {
            try {
                this.model.captchaId = await sendLoginEmailCaptcha({
                    email: this.model.email
                });

                this.countDownSeconds = 60;
                var countDown = () => {
                    if (--this.countDownSeconds <= 0) {
                        clearInterval(countDownId);
                    }
                };
                var countDownId = setInterval(countDown, 1000);
            } catch (ex) {
                this.$toast.error(ex.message);
            }
        },
        async submit (e) {
            e.preventDefault();
            if (!this.$refs.form.validate()) return;

            this.isSubmitting = true;
            this.resetFormErrors();

            var postData = _.defaults(
                {
                    rememberLogin: this.model.rememberLogin && this.passportViewModel.allowRememberLogin
                },
                this.model);

            try {
                var { redirect } = await loginWithEmail(postData);
                // 跳转到指定地址, 因为redirect地址不一定是本站，所以需要用window.location.href
                // 而不能使用 this.$router.push(redirect); 因为$router.push不会访问到服务器
                window.location.href = redirect;
            } catch (ex) {
                this.setFormErrors(ex);
                this.$toast.error(ex.message);
            } finally {
                this.isSubmitting = false;
            }
        },
        changeLoginType (e, loginType) {
            this.$emit('loginTypeChanged', { loginType });
        }
    },
    created () {
        this.model.returnUrl = this.passportViewModel.returnUrl;
    }
};
</script>
